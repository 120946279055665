export default {
    props: ['id', 'title'],
    data() {
        return {
        }
    },
    created() {

        const audio = new Audio(require('../../../assets/sounds/CONFIRM.mp3'))
        audio.play();

    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        confirmRemove(id) {
            this.$emit('removeItem', id)
        },
        cancelRemove() {
            this.$emit('closePopup')
        }
    }
}